import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import ReactPlayer from 'react-player';
import Disco from '../assets/disco.svg';

import Modal from '../components/modal';
import FreeClassButton from '../components/free-class-button';

const Home = ({ className }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const data = useStaticQuery(graphql`
    query HomeImages {
      boy: file(relativePath: { eq: "chico-inicio.png" }) {
        childImageSharp {
          gatsbyImageData(width: 900, layout: CONSTRAINED)
        }
      }
    }
  `);
  return (
    <section className={`${className}`}>
      <div className="p-3 lg:w-1/2">
        <h2 className="font-header leading-none text-purple-500 mb-6 text-3xl uppercase text-center lg:text-left lg:max-w-sm xl:mt-24">
          ¿Estás desarrollando el talento musical de tu hijo?
        </h2>
        <p className="text-gray-500 text-center mb-3 text-lg font-light lg:text-left lg:text-sm lg:mb-6 lg:max-w-sm">
          Clases diseñadas para que tu hijo se enamore de la música, aproveche
          su tiempo libre y potencie sus talentos.
        </p>
        <div className="flex flex-col lg:flex lg:flex-row relative lg:z-20">
          <FreeClassButton text="Pide una clase demo" />
        </div>
      </div>
      <div className="flex -mt-24 justify-between px-6 lg:-mt-6 ">
        <div className="w-72 -mb-24 -ml-24 -mt-12 pointer-events-none md:-mt-2 lg:-mb-56">
          <GatsbyImage
            image={data.boy.childImageSharp.gatsbyImageData}
            alt="Niño tocando guitarra"
          />
        </div>
        <Disco className="w-40 -mr-12 lg:mr-0 pointer-events-none lg:mt-10 3xl:-mt-12 6xl:-mt-32" />
      </div>
      <Modal
        isModalOpen={isModalOpen}
        closeCallback={() => setIsModalOpen(false)}
      >
        <div className="player-wrapper">
          <ReactPlayer
            url="https://vimeo.com/340453656"
            playing
            controls
            width="100%"
            height="100%"
            className="video-player"
          />
        </div>
      </Modal>
    </section>
  );
};

Home.propTypes = {
  className: PropTypes.string,
};

Home.defaultProps = {
  className: '',
};

export default Home;
