import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { FaTimesCircle } from 'react-icons/fa';

const Modal = ({ children, closeCallback, isModalOpen }) => (
  <ReactModal
    isOpen={isModalOpen}
    shouldCloseOnOverlayClick
    contentLabel="Modal"
    ariaHideApp={false}
    onRequestClose={closeCallback}
    style={{
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      content: {
        padding: '2.5rem',
        position: 'relative',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        maxHeight: '100vh',
        maxWidth: '100vw',
      },
    }}
  >
    <div className="absolute right-0 pr-3 top-0 pt-2 text-2xl cursor-pointer">
      <FaTimesCircle onClick={closeCallback} />
    </div>
    {children}
  </ReactModal>
);

Modal.propTypes = {
  children: PropTypes.node,
  closeCallback: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
};

Modal.defaultProps = {
  children: {},
};

export default Modal;
