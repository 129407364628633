import React from 'react';
import PropTypes from 'prop-types';

const HowItWorksMobile = ({ className }) => {
  return (
    <section className={`${className} text-white`}>
      <div className="how-it-works pt-12 sm:pt-24 md:pt-32">
        <h2 className="font-header leading-none bg-purple-400 pb-6 uppercase text-center text-4xl flex md:items-center md:justify-center md:w-full md:pb-20 md:text-5xl">
          <span>¿Cómo funciona?</span>
        </h2>
      </div>
      <div>
        <div className="flex bg-purple-500 py-6 px-4 items-center md:flex-col md:px-40">
          <h3 className="font-header leading-none uppercase text-center text-2xl w-1/3 mr-3 md:w-full md:mb-3">
            1. Clase gratis
          </h3>
          <p className="text-xs w-2/3 md:text-base md:text-center">
            Tu hijo podrá explorar todos los instrumentos en su primera clase
            gratis y escoger el que más le guste.
          </p>
        </div>
        <div className="flex flex-col bg-purple-600 py-6 px-4 md:px-40">
          <h3 className="font-header leading-none uppercase text-center text-2xl mb-3">
            2. Inscríbete
          </h3>
          <p className="text-xs text-center md:text-base">
            Alegría, ilusión y la satisfacción de haber tomado la mejor
            desición, es lo que sentirás cuando inscribas a tu hijo, al saber
            que ahora estará aprovechando su tiempo al máximo.
          </p>
        </div>
        <div className="flex flex-col bg-purple-700 py-6 px-4 pb-20 md:px-40 md:pb-24">
          <h3 className="font-header leading-none uppercase text-center text-2xl mb-3">
            3. Mira cómo tu hijo se enamora de la música
          </h3>
          <p className="text-xs text-center md:text-base">
            Nosotros nos encargamos de que a tu hijo le encante venir a sus
            clases. Nunca tendrás que obligarlo.
          </p>
        </div>
      </div>
    </section>
  );
};

HowItWorksMobile.propTypes = {
  className: PropTypes.string,
};

HowItWorksMobile.defaultProps = {
  className: '',
};

export default HowItWorksMobile;
