import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

const HowItWorks = ({ className }) => {
  const data = useStaticQuery(graphql`query HowItWorksImages {
  freeClass: file(relativePath: {eq: "clase-gratis.png"}) {
    childImageSharp {
      gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
    }
  }
  subscribe: file(relativePath: {eq: "inscribete.png"}) {
    childImageSharp {
      gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
    }
  }
  fallInLoveMusic: file(relativePath: {eq: "hijo-musica.png"}) {
    childImageSharp {
      gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
    }
  }
}
`);
  return (
    <section className={`${className} how-it-works`}>
      <div className="container mx-auto px-12 pt-48 2xl:pb-12 3xl:pt-48 4xl:pt-56 5xl:pt-64 6xl:pt-72">
        <div className="">
          <h2 className="font-header leading-none text-orange-500 uppercase text-4xl w-48 absolute ml-32 mt-2">
            <span>¿Cómo funciona?</span>
          </h2>
        </div>
        <div className="pt-20 mt-6 xl:pt-0">
          <div className="flex items-center justify-center">
            <div className="w-1/3">
              <h3 className="font-header leading-none text-purple-500 uppercase text-2xl mb-2">
                1. Clase gratis
              </h3>
              <p className="">
                Tu hijo podrá explorar todos los instrumentos en su primera
                clase gratis y escoger el que más le guste.
              </p>
            </div>
            <div className="w-1/3">
              <GatsbyImage image={data.freeClass.childImageSharp.gatsbyImageData} alt="Clase gratis" />
            </div>
          </div>
          <div className="flex items-center justify-center -mt-10 xl:-mt-20">
            <div className="w-1/3">
              <GatsbyImage image={data.subscribe.childImageSharp.gatsbyImageData} alt="Inscríbete" />
            </div>
            <div className="w-1/3">
              <h3 className="font-header leading-none text-teal-500 uppercase text-2xl mb-2">
                2. Inscríbete
              </h3>
              <p className="">
                Alegría, ilusión y la satisfacción de haber tomado la mejor
                desición, es lo que sentirás cuando inscribas a tu hijo, al
                saber que ahora estará aprovechando su tiempo al máximo.
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center -mt-10 xl:-mt-20">
            <div className="w-1/3">
              <h3 className="font-header leading-none text-orange-500 uppercase text-2xl mb-2">
                3. Mira cómo tu hijo se enamora de la música
              </h3>
              <p className="">
                Nosotros nos encargamos de que a tu hijo le encante venir a sus
                clases. Nunca tendrás que obligarlo.
              </p>
            </div>
            <div className="w-1/3">
              <GatsbyImage
                image={data.fallInLoveMusic.childImageSharp.gatsbyImageData}
                alt="Mira cómo tu hijo se enamora de la Música" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

HowItWorks.propTypes = {
  className: PropTypes.string,
};

HowItWorks.defaultProps = {
  className: '',
};

export default HowItWorks;
