import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Home from '../containers/home';
import Hours from '../containers/hours';
import HowItWorksMobile from '../containers/how-it-works-mobile';
import HowItWorks from '../containers/how-it-works';
import LargestSchool from '../containers/largest-school';

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Desarrolla el talento musical de tu hijo" />
      <Home className="relative overflow-hidden lg:overflow-visible lg:flex lg:justify-around lg:max-w-6xl lg:mx-auto" />
      <Hours className="relative -mt-32 sm:-mt-48 md:-mt-56 lg:-mt-48 3xl:-mt-56  4xl:-mt-64  5xl:-mt-72  6xl:-mt-80" />
      <section id="como-funciona">
        <HowItWorksMobile className="relative z-10 -mt-12 sm:-mt-20 md:-mt-32 lg:hidden" />
        <HowItWorks className="relative z-10 -mt-24 pb-24 hidden lg:block 3xl:-mt-32 4xl:-mt-40 5xl:-mt-48" />
      </section>
      <LargestSchool className="relative z-10 -mt-40 md:-mt-40 lg:-mt-40 lg:pb-16 2xl:-mt-48 3xl:-mt-56 4xl:-mt-64 5xl:-mt-72" />
    </Layout>
  );
};

export default IndexPage;
