import React from 'react';
import PropTypes from 'prop-types';
import FreeClassButton from '../components/free-class-button';
import { graphql, useStaticQuery } from 'gatsby';

const QuantityElement = ({ className, number, title, publicURL }) => (
  <div className="w-1/2">
    <div
      style={{ backgroundImage: `url(${publicURL})` }}
      className={`${className} font-header numbers w-24 h-24 mx-auto flex items-center justify-center text-white lg:w-32 lg:h-32 lg:text-2xl`}
    >
      {number}
    </div>
    <div className="font-body text-white uppercase font-light text-sm mt-3 mb-8 lg:text-base">
      {title}
    </div>
  </div>
);

QuantityElement.propTypes = {
  className: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const LargestSchoolTitle = ({ className, mobile }) => {
  return (
    <h2 className={`${className} w-full flex largest-school-title`}>
      <div className="w-full">
        <svg viewBox="0 -5 130 25.4" className="relative z-10">
          <path
            id="text-guide"
            fill="transparent"
            d="M0,20.2C1.4,19.4,22.9,7.8,62.6,8c39,0.2,59.8,11.6,61.3,12.4"
          />
          <text width="563.06" className="text-white">
            <textPath
              className="fill-current uppercase text-sm relative"
              xlinkHref="#text-guide"
            >
              &nbsp;Somos la escuela
            </textPath>
          </text>
        </svg>
        <svg viewBox="0 -5 130 25.4" className="relative z-20 subtitle-1">
          <path
            id="text-guide-2"
            fill="transparent"
            d="M0,20.2C1.4,19.4,22.9,7.8,62.6,8c39,0.2,59.8,11.6,61.3,12.4"
          />
          <text width="563.06" className="text-white">
            <textPath
              className="fill-current uppercase text-xs relative"
              xlinkHref="#text-guide-2"
            >
              &nbsp;&nbsp;&nbsp;{mobile && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
              de música
            </textPath>
          </text>
          <text width="563.06" className="text-yellow-500">
            <textPath
              className="fill-current uppercase text-xs relative"
              xlinkHref="#text-guide-2"
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {mobile && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
              más
            </textPath>
          </text>
        </svg>
        <svg viewBox="0 -5 130 25.4" className="relative z-30 subtitle-2">
          <path
            id="text-guide-3"
            fill="transparent"
            d="M0,20.2C1.4,19.4,22.9,7.8,62.6,8c39,0.2,59.8,11.6,61.3,12.4"
          />
          <text width="563.06" className="text-yellow-500">
            <textPath
              className="fill-current uppercase text-2xs relative"
              xlinkHref="#text-guide-3"
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {mobile && <>&nbsp;&nbsp;&nbsp;</>}grande del Ecuador
            </textPath>
          </text>
        </svg>
      </div>
    </h2>
  );
};
LargestSchoolTitle.propTypes = {
  className: PropTypes.string.isRequired,
  mobile: PropTypes.bool,
};
LargestSchoolTitle.defaultProps = {
  className: '',
  mobile: false,
};

const LargestSchool = ({ className }) => {
  const data = useStaticQuery(graphql`
    query DataNumberImages {
      students: file(relativePath: { eq: "numeros-estudiantes.svg" }) {
        publicURL
      }
      hours: file(relativePath: { eq: "numeros-horas-de-clase.svg" }) {
        publicURL
      }
      parents: file(relativePath: { eq: "numeros-padres.svg" }) {
        publicURL
      }
      sheets: file(relativePath: { eq: "numeros-partituras.svg" }) {
        publicURL
      }
    }
  `);
  return (
    <section
      className={`${className} largest-school pt-32 md:pt-40 lg:pt-32 3xl:pt-56 5xl:pt-72`}
    >
      <div className="font-header">
        <div className="flex justify-center w-full pt-6 lg:justify-between lg:items-center lg:hidden">
          <LargestSchoolTitle mobile />
        </div>
        <div className="container mx-auto justify-center w-full pt-6 lg:justify-between lg:items-center hidden lg:flex">
          <LargestSchoolTitle className="lg:w-1/2" />
          <div className="hidden lg:flex w-full cta-button items-center">
            <div>
              <FreeClassButton alternate text="Pide una clase demo" />
            </div>
          </div>
        </div>
        <div className="flex justify-center flex-wrap container mx-auto lg:px-12">
          <div className="flex justify-baseline text-center w-full md:w-1/2">
            <QuantityElement
              publicURL={data.students.publicURL}
              number="729"
              title="Estudiantes activos"
              className="class-hours"
            />
            <QuantityElement
              publicURL={data.hours.publicURL}
              number="123"
              title="Shows en vivo"
              className="students"
            />
          </div>
          <div className="flex justify-baseline text-center w-full md:w-1/2">
            <QuantityElement
              publicURL={data.parents.publicURL}
              number="56.214"
              title="Horas impartidas"
              className="parents"
            />
            <QuantityElement
              publicURL={data.sheets.publicURL}
              number="69.325"
              title="Canciones enseñadas"
              className="music-sheets"
            />
          </div>
        </div>
        <div className="flex">
          <div className="flex mb-6 lg:hidden mx-auto w-full">
            <FreeClassButton alternate text="Pide una clase demo" />
          </div>
        </div>
      </div>
    </section>
  );
};

LargestSchool.propTypes = {
  className: PropTypes.string,
};

LargestSchool.defaultProps = {
  className: '',
};

export default LargestSchool;
