import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import ControlJuego from '../assets/control-juego.svg';
import Televisor from '../assets/televisor.svg';
import Celular from '../assets/celular.svg';
import Tablet from '../assets/tablet.svg';

const HoursContent = ({ className }) => {
  return (
    <div className={`${className}`}>
      <div className="grow mt-6 container mx-auto sm:p-0">
        <div className="text-center text-base text-yellow-900 mb-3 sm:text-left sm:normal-case sm:-mb-3 md:-mb-12 lg:-mb-6">
          Varios estudios han demostrado que los niños y jóvenes entre 4-17 años
          pasan al rededor de 4 horas diarias en diferentes dispositivos
          electrónicos como:
        </div>
      </div>
    </div>
  );
};
HoursContent.propTypes = {
  className: PropTypes.string.isRequired,
};

const Hours = ({ className }) => {
  const data = useStaticQuery(graphql`
    query HomeHoursImages {
      girlMobile: file(relativePath: { eq: "chica-movil.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600, layout: CONSTRAINED)
        }
      }
      girl: file(relativePath: { eq: "chica.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600, layout: CONSTRAINED)
        }
      }
      clock: file(relativePath: { eq: "reloj.png" }) {
        childImageSharp {
          gatsbyImageData(width: 900, layout: CONSTRAINED)
        }
      }
      bubbleMobile: file(relativePath: { eq: "burbuja-movil.svg" }) {
        publicURL
      }
      bubble: file(relativePath: { eq: "burbuja.svg" }) {
        publicURL
      }
      leafs: file(relativePath: { eq: "fondo-inicio-plantas.svg" }) {
        publicURL
      }
    }
  `);
  return (
    <section className={`${className}`}>
      <div
        style={{ backgroundImage: `url(${data.leafs.publicURL})` }}
        className="hours-plants absolute right-0 top-0 -mt-10  w-screen h-32 md:-mt-12 lg:-mt-10 lg:hidden"
      ></div>
      <div className="hours relative z-10 pointer-events-none sm:pb-20 md:pb-24 lg:pb-0">
        <div className="container p-0 lg:p-2 lg:mx-auto lg:flex ">
          <div className="pt-16 lg:flex lg:mt-48 4xl:pt-32 5xl:pt-68 3xl:pb-4 4xl:pb-10 5xl:pb-16">
            <div className="flex items-center flex-wrap justify-around md:justify-center p-2 container mx-auto lg:w-1/2">
              <div className="clock w-5/12 md:w-4/12 md:mt-24 lg:mt-0 lg:w-5/12">
                <GatsbyImage
                  image={data.clock.childImageSharp.gatsbyImageData}
                  alt="Reloj"
                />
              </div>
              <div className="w-6/12 text-white text-shadow uppercase text-left md:pl-6 lg:pl-6">
                <div className="font-header leading-none text-6xl sm:-mb-4 sm:leading-normal md:leading-tight md:text-7xl">
                  1460
                </div>
                <div className="font-header leading-none text-2xl sm:-mb-2 sm:leading-normal md:leading-tight md:text-4xl">
                  Horas al año
                </div>
                <div className="font-header leading-none text-base sm:-mb-4 sm:leading-normal md:leading-tight md:text-3xl">
                  Sin aprovecharse
                </div>
                <HoursContent className="hidden sm:block" />
              </div>
              <HoursContent className="sm:hidden" />
              <div className="flex w-full justify-around items-center mt-3 mb-8 sm:flex-row-reverse sm:w-1/2 sm:ml-auto sm:mr-8 md:mt-6 md:w-2/5 md:mr-32 lg:w-1/2 lg:mt-6 lg:mr-8">
                <div className="flex justify-around w-full">
                  <ControlJuego className="w-12" />
                  <Televisor className="w-16" />
                </div>
                <div className="flex justify-around w-full">
                  <Celular className="w-8" />
                  <Tablet className="w-10" />
                </div>
              </div>
            </div>
            <div className="flex items-end -mt-16 sm:hidden lg:flex lg:w-1/3">
              <div className="w-2/3 lg:hidden z-20">
                <GatsbyImage
                  image={data.girlMobile.childImageSharp.gatsbyImageData}
                  alt="Chica cantando"
                />
              </div>
              <div className="w-2/3 hidden lg:block lg:z-20">
                <GatsbyImage
                  image={data.girl.childImageSharp.gatsbyImageData}
                  alt="Chica cantando"
                />
              </div>
              <div className="w-1/3 z-10 relative">
                <h4
                  className="bubble text-center text-sm font-bold block sm:hidden"
                  style={{
                    backgroundImage: `url(${data.bubbleMobile.publicURL})`,
                  }}
                >
                  <span className="font-header leading-tight text-orange-500 small-caps block -mt-2">
                    ¿Te imaginas lo que puede aprender tu hijo utilizando la
                    mitad de ese tiempo?
                  </span>
                </h4>
                <h4
                  className="bubble text-center text-sm font-bold hidden md:block"
                  style={{
                    backgroundImage: `url(${data.bubble.publicURL})`,
                  }}
                >
                  <span className="font-header leading-tight text-orange-500 small-caps block -mt-2">
                    ¿Te imaginas lo que puede aprender tu hijo utilizando la
                    mitad de ese tiempo?
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Hours.propTypes = {
  className: PropTypes.string,
};

Hours.defaultProps = {
  className: '',
};

export default Hours;
